import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import giveawaysService from "../giveaway-service";
import toast from "react-hot-toast";
import settingsService from "../settings-service";

interface MutationData {
  name: string;
  description: string;
}

export function useCreateTag(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, description }: MutationData) =>
      settingsService.createTag({
        description,
        name,
      }),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries([giveawaysService.categoriesQueryKey]);
        toast.success(`tag created.`);
      },
      onSettled: () => {},
      ...configOptions,
    }
  );
}

import { FunctionComponent } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: FunctionComponent<FallbackProps> = props => {
  return (
    <div className="flex flex-col min-h-full">
      <main className="flex flex-col flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex-shrink-0 py-16 my-auto sm:py-32">
          <p className="text-sm font-semibold tracking-wide text-indigo-600 uppercase">On no</p>
          <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            Something bad happend
          </h1>
          <p className="mt-2 text-base text-gray-500">{props.error.message}</p>
          <div className="mt-6">
            <a href="#" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
              Go back home<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ErrorFallback;

import { format } from "date-fns";
import { formatInTimeZone, zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

const timeZone = "Europe/London";

export function formatDate(date: number | string | Date | null, pattern = "dd-MM-y HH:mm") {
  if (!date) {
    return format(new Date(), pattern);
  }
  return format(new Date(date), pattern);
}

export function formatDateToTimeZone(
  date: number | string | Date | null,
  pattern = "dd-MM-y HH:mm"
) {
  if (!date) {
    return formatInTimeZone(new Date(), timeZone, pattern);
  }
  return formatInTimeZone(new Date(date), timeZone, pattern);
}

export function zonedTimeToUtcFn(date: string | number | Date) {
  return zonedTimeToUtc(date, timeZone);
}

export function utcToZonedTimeFn(date: string | number | Date) {
  return utcToZonedTime(date, timeZone);
}

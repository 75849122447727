import { AxiosError } from 'axios';

interface FormErrorProps {
  error: AxiosError | null | undefined | unknown | Array<unknown>;
  takeSpace?: boolean;
}

const FormError = ({ error, takeSpace = false }: FormErrorProps) => {
  if (Array.isArray(error)) {
    const err = error.find((e) => !!e);
    return (
      <>
        <p
          className={`flex items-center justify-center text-sm text-red-500 capitalize first-letter:capitalize font-medium text-center ${
            takeSpace ? 'min-h-[40px]' : 'min-h-0 m-0'
          }`}
        >
          {err instanceof AxiosError && (err.response?.data.message ?? 'Sorry There is a problem.')}
        </p>
      </>
    );
  }
  return (
    <>
      <p
        className={`flex items-center justify-center text-sm text-red-500 capitalize first-letter:capitalize font-medium text-center ${
          takeSpace ? 'min-h-[40px]' : 'min-h-0 m-0'
        }`}
      >
        {error &&
          error instanceof AxiosError &&
          (error.response?.data.message ?? 'Sorry There is a problem.')}
      </p>
    </>
  );
};

export default FormError;

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetGiveaways } from "../../services/hooks/use-get-giveaways";
import { useFullPageLoadingSlice } from "../../store/loading-slice";
import GiveawayTableBody from "./components/giveaway-table-body";
import GiveawayTableHeader from "./components/giveaway-table-header";
import { useGetGiveawaysDashboard } from "../../services/hooks/use-get-dashboard";

const GiveawayList = () => {
  const navigate = useNavigate();

  const updateLoading = useFullPageLoadingSlice((state) => state.updateLoading);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<number>(parseInt(searchParams.get("page") || "1"));

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [submittedFrom, setSubmittedFrom] = useState<string | undefined>(undefined);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string | null>(
    searchParams.get("status") || "All"
  );

  const {
    data: giveaways,
    isLoading: giveawaysLoading,
    refetch,
  } = useGetGiveaways(page, rowsPerPage, isExpired, submittedFrom);
  const { data: dashboard } = useGetGiveawaysDashboard();

  const handleSelectTab = (tabName: string) => {
    setPage(1);
    setRowsPerPage(10);

    switch (tabName) {
      case "All":
        setSubmittedFrom("All");
        setIsExpired(false);
        setSearchParams({ page: `${page}`, status: tabName });
        refetch();
        break;
      case "Admin":
        setSubmittedFrom("admin");
        setIsExpired(false);
        setSearchParams({ page: `${page}`, status: tabName });
        break;
      case "Crawl":
        setSubmittedFrom("crawl");
        setIsExpired(false);
        setSearchParams({ page: `${page}`, status: tabName });
        break;
      case "Website":
        setSubmittedFrom("submit form");
        setIsExpired(false);
        setSearchParams({ page: `${page}`, status: tabName });
        break;
      case "Expired":
        setSubmittedFrom("All");
        setIsExpired(true);
        setSearchParams({ page: `${page}`, status: tabName });
        break;

      default:
        setSubmittedFrom(undefined);
        setIsExpired(false);
        setSearchParams({ page: `${page}`, status: tabName });
        break;
    }
    setSelectedTab(tabName);
  };

  useEffect(() => {
    updateLoading(giveawaysLoading);
  }, [giveawaysLoading, updateLoading]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-6 mb-[200px] min-h-screen  mx-auto max-w-7xl">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Giveaways</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the giveaways in your account including their image , contest name ,
          duration , winner annnounces date, prize , created by and action
        </p>
      </div>

      {/* cards */}
      <div className="w-full grid 2xl:grid-cols-6 grid-cols-3 gap-4 place-items-center mt-6 ">
        <div
          className={`cursor-pointer border px-8 py-4 flex flex-col gap-2 rounded-md w-[180px] hover:bg-gray-100 ${
            selectedTab === "All" ? "bg-gray-50" : "bg-transparent"
          }`}
          onClick={(e) => handleSelectTab("All")}
        >
          <div className="text-gray-400 text-[10px] flex items-center justify-center">All</div>
          <div className="flex items-center justify-center gap-2">
            {/* <Icon className="h-10 w-10 text-gray-400" /> */}
            <div className="font-bold text-5xl text-gray-600">{dashboard?.data.total || 0}</div>
          </div>
        </div>
        <div
          className={`cursor-pointer border px-8 py-4 flex flex-col gap-2 rounded-md w-[180px] hover:bg-gray-100 ${
            selectedTab === "Admin" ? "bg-gray-50" : "bg-transparent"
          }`}
          onClick={(e) => handleSelectTab("Admin")}
        >
          <div className="text-gray-400 text-[10px] flex items-center justify-center">Admin</div>
          <div className="flex items-center justify-center gap-2">
            {/* <Icon className="h-10 w-10 text-gray-400" /> */}
            <div className="font-bold text-5xl text-gray-600">{dashboard?.data.admin || 0}</div>
          </div>
        </div>
        <div
          className={`cursor-pointer border px-8 py-4 flex flex-col gap-2 rounded-md w-[180px] hover:bg-gray-100 ${
            selectedTab === "Crawl" ? "bg-gray-50" : "bg-transparent"
          }`}
          onClick={(e) => handleSelectTab("Crawl")}
        >
          <div className="text-gray-400 text-[10px] flex items-center justify-center">Crawled</div>
          <div className="flex items-center justify-center gap-2">
            {/* <Icon className="h-10 w-10 text-gray-400" /> */}
            <div className="font-bold text-5xl text-gray-600">{dashboard?.data.crawled || 0}</div>
          </div>
        </div>
        <div
          className={`cursor-pointer border px-8 py-4 flex flex-col gap-2 rounded-md w-[180px] hover:bg-gray-100 ${
            selectedTab === "Website" ? "bg-gray-50" : "bg-transparent"
          }`}
          onClick={(e) => handleSelectTab("Website")}
        >
          <div className="text-gray-400 text-[10px] flex items-center justify-center">Website</div>
          <div className="flex items-center justify-center gap-2">
            {/* <Icon className="h-10 w-10 text-gray-400" /> */}
            <div className="font-bold text-5xl text-gray-600">{dashboard?.data.website || 0}</div>
          </div>
        </div>
        <div
          className={`cursor-pointer border px-8 py-4 flex flex-col gap-2 rounded-md w-[180px] hover:bg-gray-100 ${
            selectedTab === "Expired" ? "bg-gray-50" : "bg-transparent"
          }`}
          onClick={(e) => handleSelectTab("Expired")}
        >
          <div className="text-gray-400 text-[10px] flex items-center justify-center">Expired</div>
          <div className="flex items-center justify-center gap-2">
            <div className="font-bold text-5xl text-gray-600">{dashboard?.data.expired || 0}</div>
          </div>
        </div>
        <button
          className={`border px-4 h-full py-6 text-white flex flex-col items-center gap-2 rounded-md w-[180px] bg-prm `}
          onClick={() => navigate("add-giveaway")}
        >
          <span className="">Add New</span> <span className="">Giveaway</span>
        </button>
      </div>

      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            {/* table header */}

            <GiveawayTableHeader />

            <GiveawayTableBody
              giveawayList={giveaways?.data?.giveAways}
              count={giveaways?.data?.count || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GiveawayList;

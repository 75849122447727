import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./app/app";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./app/components/error-fallback";
import Loading from "./app/components/libs/loading";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { persister, queryClient, toastOptions } from "./config";
import { QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import bgLocale from "date-fns/locale/en-GB";
import { theme } from "./theme/mui-theme";
import { ThemeProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <React.Suspense fallback={<Loading />}>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={bgLocale}>
                <ThemeProvider theme={theme}>
                  <App />
                  <Toaster toastOptions={toastOptions} />
                  {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </ThemeProvider>
              </LocalizationProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </PersistQueryClientProvider>
      </React.Suspense>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();

import create from "zustand";

type State = {
  loading: boolean;
};

type Actions = {
  updateLoading: (loading: boolean) => void;
};

const initialState: State = {
  loading: false,
};

export const useFullPageLoadingSlice = create<State & Actions>()((set, get) => ({
  ...initialState,
  updateLoading: (loading: boolean) => {
    set({ loading });
  },
}));

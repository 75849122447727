import { useState } from "react";
import { Outlet } from "react-router-dom";
import FullpageLoading from "../../components/libs/fullpage-loading";
import Header from "./components/header";
import Layout from "./components/layout";

const Home = () => {
  
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  return (
    <>
      <FullpageLoading />
      <Layout
        outlet={<Outlet />}
        header={
          <Header
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        }
        setMobileMenuOpen={setMobileMenuOpen}
      />
    </>
  );
};
export default Home;

import { useState } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

interface TableActionProps {
  giveawayId: string;
  instagramUrl: string;
  openDelete: (arg0: boolean) => void;
  openActive: (arg0: boolean) => void;
  openFeatured: (arg0: boolean) => void;
  flag: boolean;
  status: string;
}

const TableAction = ({
  instagramUrl,
  giveawayId,
  openDelete,
  openActive,
  openFeatured,
  status,
  flag,
}: TableActionProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  return (
    <div>
      <button
        className="bg-[#EDF0F0] p-2  rounded-md"
        type="button"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
      </button>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            window.open(instagramUrl, "_blank");
            handleClose();
          }}
        >
          Go to instagram
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`edit-giveaway?giveawayId=${giveawayId}`);
            handleClose();
          }}
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            openFeatured(true);
            handleClose();
          }}
        >
          {flag ? "Unfeature" : "Feature"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDelete(true);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            openActive(true);
            handleClose();
          }}
        >
          {status === "Active" ? "Deactivate" : "Activate"}
        </MenuItem>
      </Menu>
    </div>
  );
};
export default TableAction;

//  <div className="py-1">
//             <Menu.Item>
//               <button
//                 className=" px-4 py-2 text-sm text-gray-700 w-full flex hover:bg-prm hover:text-white"
//                 onClick={() =>}
//               >

//               </button>
//             </Menu.Item>
//             <Menu.Item>
//               <button
//                 className=" px-4 py-2 text-sm text-gray-700 hover:bg-prm hover:text-white flex w-full"
//                 onClick={() => }
//                 disabled={}
//               >

//               </button>
//             </Menu.Item>
//             <Menu.Item>
//               <button
//                 className=" px-4 py-2 text-sm text-gray-700 hover:bg-prm hover:text-white flex w-full"
//                 onClick={() => }
//               >

//               </button>
//             </Menu.Item>
//             <Menu.Item>
//               <button
//                 className=" px-4 py-2 text-sm text-gray-700 hover:bg-prm hover:text-white flex w-full"
//                 onClick={() => }
//               >
//                 Delete
//               </button>
//             </Menu.Item>
//             <Menu.Item>
//               <button
//                 className=" px-4 py-2 text-sm text-gray-700 hover:bg-prm hover:text-white flex w-full"
//                 onClick={() => }
//               >
//               </button>
//             </Menu.Item>
//           </div>

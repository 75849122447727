import http from "./axios-instance";
import { Login, LoginRequest } from "../services/interfaces/login-interface";

const login = async (data: LoginRequest): Promise<Login> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/login`;
  const res = await http.post(url, data);
  return res.data;
};

const authService = {
  login,
};

export default authService;

const GiveawayTableHeader = () => {
  return (
    // <thead className="bg-gray-50">
    //   <tr className="uppercase">
    //     <th
    //       scope="col"
    //       className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
    //     >
    //       Name
    //     </th>
    //     <th
    //       scope="col"
    //       className="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell"
    //     >
    //       Start
    //     </th>
    //     <th
    //       scope="col"
    //       className="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell"
    //     >
    //       End
    //     </th>
    //     <th
    //       scope="col"
    //       className="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell "
    //     >
    //       Pick Winner
    //     </th>
    //     <th
    //       scope="col"
    //       className="hidden md:table-cell px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
    //     >
    //       Caption
    //     </th>
    //     <th scope="col" className=" px-3 py-3.5 text-center text-sm font-semibold text-gray-900 ">
    //       Created By
    //     </th>
    //     <th
    //       scope="col"
    //       className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-center text-sm font-semibold text-gray-900"
    //     >
    //       <span className="sr-only">Edit</span>
    //       Action
    //     </th>
    //   </tr>
    // </thead>

    <div className="min-w-full divide-y divide-gray-300">
      <div className="grid items-center w-full h-16 grid-cols-9 text-gray-500 bg-gray-50 xl:text-sm text-xs ">
        <div className="flex items-center justify-start pl-4 text-center col-span-2">Name</div>
        <div className="flex items-center justify-center text-center col-span-1">Start</div>
        <div className="flex items-center justify-center text-center col-span-1">End</div>
        <div className="flex items-center justify-center text-center col-span-1">
          Winner Announcement
        </div>
        <div className="flex items-center justify-center text-center col-span-1">Caption</div>
        {/* <div className="flex items-center justify-center text-center col-span-2">STATUS</div> */}
        <div className="flex items-center justify-center text-center col-span-1">Created By</div>
        <div className="flex items-center justify-end text-center col-span-1">ACTION</div>
      </div>
    </div>
  );
};

export default GiveawayTableHeader;

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { useGetCrawledSettings } from "../../../../services/hooks/use-get-crawl-settings";
import NoDataSpinner from "../../../../components/no-data-spinner";
import { useAddHashtag } from "../../../../services/hooks/use-add-hashtags";
import { useChangeToken } from "../../../../services/hooks/use-change-token";
import { useChangeJob } from "../../../../services/hooks/use-change-hour";
import { useEffect } from "react";
import { useUpdateCrawlSettings } from "../../../../services/hooks/use-update-crawel-settings";

type FormData = {
  fbToken: { token: string; userId: string }[];
  aiToken: { token: string }[];
  hashtags: string;
  job: number | string;
};

const schema = yup.object({
  fbToken: yup.array().of(
    yup.object({
      token: yup.string().required("This field is required."),
      userId: yup.string(),
    })
  ),
  aiToken: yup.array().of(
    yup.object({
      token: yup.string().required("This field is required."),
    })
  ),
  hashtags: yup
    .string()
    .test("no-special-characters", "Field must not contain special characters", (value) => {
      if (value) {
        return !/[!@#$%^&*()_+\-~`=,\.;:'"/?<>[\].]/.test(value);
      } else {
        return true;
      }
    }),
  job: yup.string(),
});

const CrawlSettingsForm = () => {
  const { data, isLoading } = useGetCrawledSettings();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      aiToken: data?.data?.openAi?.tokens?.map((t) => ({ token: t.token })) ?? [],
      fbToken:
        data?.data?.facebook?.tokens?.map((t) => ({ token: t.token, userId: t?.user_id ?? "" })) ??
        [],
      hashtags: data?.data?.hashTags?.hashTags.join(" ") ?? "",
      job: data?.data?.cronJobSetting?.hour ?? "",
    },
  });
  const {
    fields: aiTokenFields,
    append: aiTokenAppend,
    remove: aiTokenRemove,
  } = useFieldArray({
    control,
    name: "aiToken", // unique name for your Field Array
  });
  const {
    fields: fbTokenFields,
    append: fbTokenAppend,
    remove: fbTokenRemove,
  } = useFieldArray({
    control,
    name: "fbToken", // unique name for your Field Array
  });
  const addHashtags = useAddHashtag();
  const changeToken = useChangeToken();
  const changeJobHour = useChangeJob();
  const updateCrawlSettings = useUpdateCrawlSettings();

  useEffect(() => {
    if (data?.data) {
      reset({
        aiToken: data?.data?.openAi?.tokens?.map((t) => ({ token: t.token })) ?? [],
        fbToken:
          data?.data?.facebook?.tokens?.map((t) => ({
            token: t.token,
            userId: t?.user_id ?? "",
          })) ?? [],
        hashtags: data?.data?.hashTags?.hashTags.join(" ") ?? "",
        job: data?.data?.cronJobSetting?.hour ?? "",
      });
    }
  }, [data, reset]);

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    console.log(formData);
    updateCrawlSettings.mutate({
      data: [
        {
          name: "facebook_access_token",
          tokens: formData.fbToken.map((t) => {
            return {
              token: t.token,
              user_id: t.userId,
            };
          }),
        },
        {
          name: "open_ai_access_token",
          tokens: formData.aiToken,
        },
        {
          name: "hashTags",
          hashTags: formData.hashtags.split(" "),
        },
        {
          name: "cronJob",
          hour: +formData.job,
        },
      ],
    });
  };

  return (
    <>
      {isLoading ? (
        <NoDataSpinner fullPage={false} />
      ) : (
        <form
          className="grid gap-4 p-6 border rounded-md gird-cols-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-span-6 sm:col-span-6">
            <LoadingButton
              variant="text"
              type="button"
              color="inherit"
              onClick={() => fbTokenAppend({ token: "", userId: "" })}
              sx={{
                border: "1px solid",
                backgroundColor: "#14CBC7",
                color: "#fff",
                borderRadius: "6px",
                ":hover": {
                  color: "#14CBC7",
                },
              }}
            >
              Add FB Token
            </LoadingButton>
          </div>
          {fbTokenFields.map((field, index) => (
            <div key={field.id} className="flex col-span-6 space-x-4 sm:col-span-6">
              <Controller
                name={`fbToken.${index}.token`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      type="text"
                      id="fbToken"
                      label="FB Token"
                      variant="outlined"
                      fullWidth={true}
                      {...field}
                      error={!!(errors as any)?.fbToken?.[index]?.token}
                      helperText={(errors as any)?.fbToken?.[index]?.token?.message ?? " "}
                    />
                  );
                }}
              />
              <Controller
                name={`fbToken.${index}.userId`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      type="text"
                      id="fbTokenUserId"
                      label="User ID"
                      variant="outlined"
                      fullWidth={true}
                      {...field}
                      error={!!(errors as any)?.fbToken?.[index]?.userId}
                      helperText={(errors as any)?.fbToken?.[index]?.userId?.message ?? " "}
                    />
                  );
                }}
              />
              <LoadingButton
                variant="contained"
                className="!mt-1 !w-32 !shrink-0"
                type="button"
                color="error"
                onClick={() => fbTokenRemove(index)}
              >
                Delete
              </LoadingButton>
            </div>
          ))}
          <div className="col-span-6 sm:col-span-6">
            <LoadingButton
              variant="text"
              type="button"
              color="inherit"
              onClick={() => aiTokenAppend({ token: "" })}
              sx={{
                border: "1px solid",
                backgroundColor: "#14CBC7",
                color: "#fff",
                borderRadius: "6px",
                ":hover": {
                  color: "#14CBC7",
                },
              }}
            >
              Add AI Token
            </LoadingButton>
          </div>
          {aiTokenFields.map((field, index) => (
            <div key={field.id} className="flex col-span-6 space-x-4 sm:col-span-6">
              <Controller
                name={`aiToken.${index}.token`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      type="text"
                      id="aiToken"
                      label="AI Token"
                      variant="outlined"
                      fullWidth={true}
                      {...field}
                      error={!!(errors as any)?.aiToken?.[index]?.token}
                      helperText={(errors as any)?.aiToken?.[index]?.token?.message ?? " "}
                    />
                  );
                }}
              />
              <LoadingButton
                variant="contained"
                className="!mt-1 !w-32 !shrink-0"
                type="button"
                color="error"
                onClick={() => aiTokenRemove(index)}
              >
                Delete
              </LoadingButton>
            </div>
          ))}

          <div className="col-span-6 ">
            <Controller
              name="hashtags"
              control={control}
              render={({ field }) => {
                return (
                  <div className="col-span-6 mb-4 ">
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      Hashtags
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="detail"
                        rows={5}
                        maxLength={512}
                        {...field}
                        className="block w-full text-lg border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onKeyDown={(e) => {
                          // var regex = new RegExp("/^'?p{L}+(?:[' ]p{L}+)*'?$/u");
                          var regex = new RegExp(/[!@#$%^&*()_+\-~`=,\.;:'"/?<>[\].]/);

                          if (regex.test(e.key)) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        placeholder="Separate etch hashtag with space"
                      />
                      <p className="pt-2 text-red-500 capitalize first-letter:capitalize">
                        {errors.hashtags?.message}
                      </p>
                    </div>
                  </div>
                );
              }}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <Controller
              name="job"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Cronjob</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Cronjob"
                      {...field}
                      fullWidth={true}
                      error={!!errors?.job}
                    >
                      <MenuItem value={6}>every 6 hours</MenuItem>
                      <MenuItem value={12}>every 12 hours</MenuItem>
                      <MenuItem value={24}>every 24 hours</MenuItem>
                    </Select>
                  </FormControl>
                );
              }}
            />
          </div>

          <div className="flex items-end justify-end col-span-6">
            <LoadingButton
              variant="text"
              type="submit"
              color="inherit"
              loadingIndicator={<CircularProgress style={{ color: "#ffffff" }} size={16} />}
              disabled={addHashtags.isLoading || changeToken.isLoading || changeJobHour.isLoading}
              loading={addHashtags.isLoading || changeToken.isLoading || changeJobHour.isLoading}
              sx={{
                border: "1px solid",
                backgroundColor: "#14CBC7",
                color: "#fff",
                borderRadius: "6px",
                ":hover": {
                  color: "#14CBC7",
                },
              }}
            >
              Save
            </LoadingButton>
          </div>
        </form>
      )}
    </>
  );
};
export default CrawlSettingsForm;

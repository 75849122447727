import create from "zustand";
import { persist } from "zustand/middleware";
import { compress, decompress } from "lz-string";
import { Login } from "../services/interfaces/login-interface";

type State = {
  id: string | null;
  userName: string | null;
  password: string | null;
  isActive: boolean | null;
  isDeleted: boolean | null;
  deletedAt: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  isExpired: boolean | null;
  expiresIn: number | null;
  token: string | null;
};

type Actions = {
  updateUser: (data: Login) => void;
  reset: () => void;
};

const initialState: State = {
  id: null,
  userName: null,
  password: null,
  isActive: null,
  isDeleted: null,
  deletedAt: null,
  createdAt: null,
  updatedAt: null,
  isExpired: null,
  token: null,
  expiresIn: null,
};

export const useAuthSlice = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      updateUser: (data: Login) => {
        const user = {
          id: data.data.id,
          userName: data.data.userName,
          password: data.data.password,
          isActive: data.data.isActive,
          isDeleted: data.data.isDeleted,
          deletedAt: data.data.deletedAt,
          createdAt: data.data.createdAt,
          updatedAt: data.data.updatedAt,
          isExpired: data.data.isExpired,
          token: data.token?.token,
          expiresIn: data.token?.expiresIn,
        };
        set(user);
      },
      reset: () => {
        set({ ...initialState });
      },
    }),
    {
      name: `${process.env.REACT_APP_CACHE_KEY}_USER`,
      serialize: (data) => compress(JSON.stringify(data)),
      deserialize: (data) => {
        const decompressData = decompress(data) as string;
        return JSON.parse(decompressData);
      },
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !["isAuthenticated"].includes(key))
        ),
    }
  )
);

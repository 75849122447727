import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import settingsService from "../settings-service";

interface MutationData {
  hashtags: string[];
}

export function useAddHashtag(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();

  return useMutation(({ hashtags }: MutationData) => settingsService.addHashtags(hashtags), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([settingsService.crawledSettingsQueryKey]);
      // toast.success(`tag created.`);
    },
    onSettled: () => {},
    ...configOptions,
  });
}

import GiveawayForm from "../../components/giveaway-form/giveaway-form";

const EditGiveaway = () => {
  return (
    <div className="min-h-screen h-full p-4 bg-gray-100">
      <GiveawayForm />
    </div>
  );
};
export default EditGiveaway;

import { CircularProgress } from "@mui/material";
import CrawlSettingsForm from "./components/crawl-settings-form";
import { useManualCrawl } from "../../../services/hooks/use-manual-crawl";
import { LoadingButton } from "@mui/lab";
import CrawlLog from "./components/crawl-log";

const CrawlSettings = () => {
  const manualCrawl = useManualCrawl();
  return (
    <div className="w-full h-full flex flex-col gap-6 ">
      <div className="flex items-end justify-end">
        <LoadingButton
          variant="text"
          type="button"
          color="inherit"
          loadingIndicator={<CircularProgress style={{ color: "#ffffff" }} size={16} />}
          disabled={manualCrawl.isLoading}
          loading={manualCrawl.isLoading}
          sx={{
            border: "1px solid",
            backgroundColor: "#14CBC7",
            color: "#fff",
            borderRadius: "6px",
            ":hover": {
              color: "#14CBC7",
            },
          }}
          onClick={() => manualCrawl.mutate({})}
        >
          Manual
        </LoadingButton>
      </div>
      <CrawlSettingsForm />
      <CrawlLog />
    </div>
  );
};

export default CrawlSettings;

import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import settingsService from "../settings-service";
import { CrawlSettingRequest } from "../interfaces/crawle-setting-request";

export function useUpdateCrawlSettings(
  configOptions: UseMutationOptions<any, any, CrawlSettingRequest, any> = {}
) {
  const queryClient = useQueryClient();

  return useMutation((data: CrawlSettingRequest) => settingsService.updateCrawlSettings(data), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([settingsService.crawledSettingsQueryKey]);
      // toast.success(`tag created.`);
    },
    onSettled: () => {},
    ...configOptions,
  });
}

import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import giveawaysService from "../giveaway-service";
import { DashboardGetResponse } from "../interfaces/dashboard-interface";

export function useGetGiveawaysDashboard(
  configOptions: UseQueryOptions<DashboardGetResponse> = {}
) {
  return useQuery({
    // enabled: Boolean(businessId),
    queryKey: [giveawaysService.dashboardQueryKey],
    queryFn: () => giveawaysService.getGiveawaysDashboard(),
    retryDelay: 1000 * 30,
    staleTime: 1000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess(data) {},
    onError: (err) => {},
    onSettled(data, error: any) {
      if (!data || error?.response?.status === 403) {
        // do something in response
      }
    },
    ...configOptions,
  });
}

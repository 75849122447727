import { Button, CircularProgress, Dialog, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useCreateTag } from "../../../../services/hooks/use-create-tag";
import { useEditTag } from "../../../../services/hooks/use-edit-tag";

type FormData = {
  name: string;
  description: string;
};

const schema = yup.object({
  name: yup.string().required(),
  description: yup.string().nullable(),
});

const TagFormModal = ({
  open,
  setOpen,
  name,
  description,
  id,
}: {
  open: boolean;
  setOpen: (e: boolean) => void;
  name: string | undefined;
  description: string | undefined;
  id: string | undefined;
}) => {
  const createTag = useCreateTag();
  const editTag = useEditTag();

  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: name ?? "",
      description: description ?? "",
    },
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (id) {
      editTag.mutate(
        {
          id,
          name: data.name,
          description: data.description,
        },
        {
          onSuccess: () => {
            handleCloseModal();
          },
        }
      );
    } else {
      createTag.mutate(
        {
          name: data.name,
          description: data.description,
        },
        {
          onSuccess: () => {
            handleCloseModal();
          },
        }
      );
    }
  };

  const handleCloseModal = () => {
    reset({
      name: name ?? "",
      description: description ?? "",
    });
    setOpen(false);
  };

  useEffect(() => {
    reset({
      name: name ?? "",
      description: description ?? "",
    });
  }, [name, description, reset]);

  return (
    <Dialog onClose={handleCloseModal} open={open} fullWidth={true} maxWidth={"sm"}>
      <div className="p-6">Tag Form</div>
      <form className="flex flex-col w-full p-6" onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div className="">
          <Controller
            name="name"
            control={control}
            render={({ field }) => {
              return (
                <div>
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <TextField
                    type="text"
                    id="title"
                    // label="Name"
                    variant="outlined"
                    // placeholder="name of tag"
                    fullWidth={true}
                    error={!!errors?.name}
                    helperText={errors?.name?.message ?? " "}
                    {...field}
                  />
                </div>
              );
            }}
          />
        </div>
        {/* about */}
        <div className=" ">
          <Controller
            name="description"
            control={control}
            render={({ field }) => {
              return (
                <div className=" mb-4 ">
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="detail"
                      rows={5}
                      maxLength={512}
                      {...field}
                      className="block w-full rounded-[4px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      // placeholder="This is place for your giveaway caption!"
                    />
                    <p className="pt-2 text-red-500 capitalize first-letter:capitalize">
                      {errors.description?.message}
                    </p>
                  </div>
                </div>
              );
            }}
          />
        </div>

        <div className="flex items-center justify-between w-full mx-6 pt-9 md:mx-0">
          <span className="flex items-center flex-wrap text-red-500 text-sm font-medium capitalize line-clamp-1">
            {id ? editTag.error : createTag.error}
          </span>
          <div className="flex items-center justify-end space-x-6 w-full">
            <Button
              type="button"
              variant="outlined"
              color="inherit"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="text"
              type="submit"
              color="inherit"
              // fullWidth
              loadingIndicator={<CircularProgress style={{ color: "#ffffff" }} size={16} />}
              disabled={id ? editTag.isLoading : createTag.isLoading}
              loading={id ? editTag.isLoading : createTag.isLoading}
              sx={{
                // minWidth: "250px",
                border: "1px solid",
                backgroundColor: "#2D0067",
                color: "#fff",
                borderRadius: "6px",
                ":hover": {
                  color: "#2D0067",
                },
              }}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default TagFormModal;

import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/guards/require-auth";
import AddGiveaway from "./pages/add-giveaway/add-giveaway";
import EditGiveaway from "./pages/edit-giveaway/edit-giveaway";
import GiveawayList from "./pages/giveaway-list/giveaway-list";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import NotFound from "./pages/not-found/not-found";
import Setting from "./pages/settings/settings";

function App() {
  return (
    <div className="font-dm-sans">
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/" element={<RequireAuth component={<Home />} />}>
          <Route index element={<GiveawayList />} />
          <Route path="add-giveaway" element={<AddGiveaway />} />
          <Route path="edit-giveaway" element={<EditGiveaway />} />
          <Route path="settings" element={<Setting />}></Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;

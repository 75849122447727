import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import giveawaysService from "../giveaway-service";
import { GiveawaysGetResponse } from "../interfaces/giveaway-interface";
import { SingleGiveawayResponse } from "../interfaces/single-giveaway-interface";

export function useGetGiveaways(
  page: number | null = null,
  limit: number | null = null,
  expired: boolean | undefined = undefined,
  submitted: string | undefined = undefined,
  configOptions: UseQueryOptions<GiveawaysGetResponse> = {}
) {
  return useQuery({
    queryKey: [giveawaysService.giveawaysQueryKey, page, limit, expired, submitted],
    queryFn: () => giveawaysService.getGiveaways(page, limit, expired, submitted),
    retryDelay: 1000 * 30,
    staleTime: 1000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess(data) {},
    onError: (err) => {},
    onSettled(data, error: any) {
      if (!data || error?.response?.status === 403) {
        // do something in response
      }
    },
    ...configOptions,
  });
}

export function useSingleGiveaway(
  giveawayId: string,
  configOptions: UseQueryOptions<SingleGiveawayResponse> = {}
) {
  return useQuery({
    enabled: Boolean(giveawayId),
    queryKey: [giveawaysService.singleGiveawaysQueryKey, giveawayId],
    queryFn: () => giveawaysService.getSingleGiveaway(giveawayId),
    retryDelay: 1000 * 30,
    staleTime: 1000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess(data) {},
    onError: (err) => {},
    onSettled(data, error: any) {
      if (!data || error?.response?.status === 403) {
        // do something in response
      }
    },
    ...configOptions,
  });
}

// export function useGiveaways() {
//   return useQuery([giveawaysService.giveawaysQueryKey], giveawaysService.getGiveaways, {
//     refetchOnWindowFocus: false,
//   });
// }

// export function useSingleGiveaway(giveawayId: string) {
//   return useQuery(
//     [giveawaysService.singleGiveawaysQueryKey, giveawayId],
//     giveawaysService.getSingleGiveaway,
//     {
//       refetchOnWindowFocus: false,
//     }
//   );
// }

import http from "./axios-instance";
import { CrawlLogGetREsponse, CrawledSettingsGetResponse } from "./interfaces/crawl-interface";
import { CrawlSettingRequest } from "./interfaces/crawle-setting-request";
import { SubscribeGetResponse } from "./interfaces/subscribe-interface";

const crawledSettingsQueryKey = "crawled-settings-query";
const crawlLogQueryKey = "crawl-log-query";
const subscribeQueryKey = "subscribe-query";

const getCrawlSettings = async (): Promise<CrawledSettingsGetResponse> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/crawlSetting`;
  const { data } = await http.get(url);
  return data;
};

const updateCrawlSettings = async (data: CrawlSettingRequest): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/crawlSetting`;
  const res = await http.put(url, data);
  return res.data;
};

const deleteTag = async (id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/category/${id}`;
  const { data } = await http.delete(url);
  return data;
};

const createTag = async ({
  name,
  description,
}: {
  name: string;
  description: string;
}): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/category`;
  const { data } = await http.post(url, { name, description });
  return data;
};
const editTag = async ({
  id,
  name,
  description,
}: {
  id: string;
  name: string;
  description: string;
}): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/category/${id}`;
  const res = await http.put(url, { name, description });
  return res.data.data;
};

const addHashtags = async (hashTags: string[]): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/addingHashtags`;
  const { data } = await http.post(url, { hashTags });
  return data;
};

const changeToken = async (name: string, token: string): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/changeToken`;
  const { data } = await http.post(url, { name, token });
  return data;
};

const changeJobHour = async (hour: number): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/changeCronJob`;
  const { data } = await http.post(url, { hour });
  return data;
};

const manualCrawl = async (): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/crawler`;
  const { data } = await http.get(url);
  return data;
};

const getCrawlLog = async (
  page: number | null = null,
  limit: number | null = null
): Promise<CrawlLogGetREsponse> => {
  const params = { page, limit };
  const url = `${process.env.REACT_APP_BASE_URL}/admin/crawlLogs`;
  const { data } = await http.get(url, { params });
  return data;
};

const getSubscribes = async (
  page: number | null = null,
  limit: number | null = null,
  keyword: string | null = null
): Promise<SubscribeGetResponse> => {
  const params = { page, limit, keyword };
  const url = `${process.env.REACT_APP_BASE_URL}/subscribe/get`;
  const { data } = await http.get(url, { params });
  return data;
};

const settingsService = {
  crawledSettingsQueryKey,
  getCrawlSettings,
  deleteTag,
  createTag,
  editTag,
  addHashtags,
  changeToken,
  manualCrawl,
  changeJobHour,
  getCrawlLog,
  crawlLogQueryKey,
  getSubscribes,
  subscribeQueryKey,
  updateCrawlSettings,
};

export default settingsService;

// import "./loading.scss";

import { CircularProgress } from "@mui/material";

// Dark: bg-[#2c3e50]
// Blue: bg-[#2980b9]
// Green: bg-[#27ae60]
// Gray: bg-[#2c3e50]
// Orange: bg-[#ffcb65]

export function Loading() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 h-screen w-full flex items-center justify-center z-50 bg-prm">
      <div className="grid gap-2">
        <div className="flex items-center justify-center ">
          <CircularProgress size={150} thickness={2} style={{ color: "#E5FD65" }} />
          {/* <div className="w-40 h-40 border-r-4 border-l-gray-500 border-prm2 rounded-full animate-spin"></div> */}
        </div>
      </div>
    </div>
  );
}

export default Loading;

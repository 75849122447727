import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { CategoriesGetResponse } from "../interfaces/category-interface";
import giveawaysService from "../giveaway-service";

export function useGetCategories(
  page: number | null = null,
  limit: number | null = null,
  keyword: string | null = null,

  configOptions: UseQueryOptions<CategoriesGetResponse> = {}
) {
  return useQuery({
    // enabled: Boolean(businessId),
    queryKey: [giveawaysService.categoriesQueryKey, page, limit, keyword],
    queryFn: () => giveawaysService.getCategories(page, limit, keyword),
    retryDelay: 1000 * 30,
    staleTime: 1000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess(data) {},
    onError: (err) => {},
    onSettled(data, error: any) {
      if (!data || error?.response?.status === 403) {
        // do something in response
      }
    },
    ...configOptions,
  });
}

import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import settingsService from "../settings-service";

interface MutationData {
  name: string;
  token: string;
}

export function useChangeToken(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();

  return useMutation(({ name, token }: MutationData) => settingsService.changeToken(name, token), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([settingsService.crawledSettingsQueryKey]);
      // toast.success(`tag created.`);
    },
    onSettled: () => {},
    ...configOptions,
  });
}

import { Dialog, Transition } from "@headlessui/react";
import { HomeIcon, XMarkIcon, CogIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../utils/tailwind-class-names";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FC, Fragment } from "react";
import { ReactComponent as FinderLogo } from "../../../../assets/svg/Frame 21.svg";

const sidebarNavigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  { name: "Settings", href: "/settings", icon: CogIcon, current: false },
];

interface Props {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (arg0: boolean) => void;
}

const Header: FC<Props> = ({ mobileMenuOpen, setMobileMenuOpen }) => {
  let location = useLocation();

  return (
    <>
      {/* Narrow sidebar */}
      <div className="hidden w-28 overflow-y-auto bg-prm md:block min-h-screen">
        <div className="flex w-full flex-col items-center py-6">
          <div className="flex flex-shrink-0 items-center">
            <div className="w-20 h-20">
              <FinderLogo className="w-full h-full" />
            </div>
            {/* <img
              className="h-10 w-auto"
              src={require("../../../../assets/svg/header-logo.svg").default}
              alt="Your Company"
            /> */}
          </div>
          <div className="mt-6 w-full flex-1 space-y-1 px-2">
            {sidebarNavigation.map((item) => (
              <NavLink
                end={item.href === "/"}
                key={item.name}
                to={item.href}
                className={classNames(
                  location.pathname === item.href
                    ? "bg-prm2 text-white"
                    : "text-white hover:bg-prm2 ",
                  "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                )}
                aria-current={location.pathname === item.href ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    location.pathname === item.href ? "text-white" : "text-white ",
                    "h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="mt-2">{item.name}</span>
              </NavLink>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20 md:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-prm pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <div className="w-20 h-20">
                    <FinderLogo className="w-full h-full" />
                  </div>
                  {/* <img
                    className="h-10 w-auto"
                    src={require("../../../../assets/svg/header-logo.svg").default}
                    alt="Your Company"
                  /> */}
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? "bg-prm2 text-white"
                              : "text-indigo-100 hover:bg-prm2 hover:text-white",
                            "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                          )}
                          aria-current={location.pathname === item.href ? "page" : undefined}
                        >
                          <item.icon
                            className={classNames(
                              location.pathname === item.href
                                ? "text-white"
                                : "text-indigo-300 group-hover:text-white",
                              "mr-3 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Header;

import axios from "axios";
import { useAuthSlice } from "../store/auth-slice";
import { useFullPageLoadingSlice } from "../store/loading-slice";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 10000,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error.response;
    const logout = useAuthSlice.getState().reset;
    const updateLoading = useFullPageLoadingSlice.getState().updateLoading;
    if (status === 401) {
      // TODO: refresh token
      updateLoading(false);
      logout();
    }
    return Promise.reject(error);
  }
);

http.interceptors.request.use(
  (config) => {
    const token = useAuthSlice.getState().token;
    const validBaseUrl = config.baseURL!.startsWith(`${process.env.REACT_APP_BASE_URL}`);
    if (token && validBaseUrl) {
      config.headers!.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default http;

import { useQueryClient } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthSlice } from '../../store/auth-slice';
// import { isTokenValid } from '../../utils/is-token-expired';

export interface RequireAuthProps {
  component: JSX.Element;
}

export function RequireAuth({ component }: RequireAuthProps) {
  const queryClient = useQueryClient();
  const location = useLocation();
  const isExpired = useAuthSlice((state) => state.isExpired);
  const token = useAuthSlice((state) => state.token);
  const isAuthenticated = !isExpired && token;
  if (!isAuthenticated) {
    useAuthSlice.persist.clearStorage();
    queryClient.clear();
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return component;
}

export default RequireAuth;

import { useState } from "react";
import TagSettings from "./tag-setting/tag-settings";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import CrawlSettings from "./crawl-setting/crawl-setting";
import SubscribeSettings from "./subscribe-setting/subscribe-setting";

const Setting = () => {
  const [selectedTab, setSelectedTab] = useState<string>("1");

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <div className=" w-full max-w-7xl mx-auto mt-8">
      <TabContext value={selectedTab}>
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => {
            setSelectedTab(newValue.toString());
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#4f46e5",
              color: "#4f46e5",
            },
          }}
          textColor="inherit"
          variant="fullWidth"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab
            style={{
              textTransform: "none",
            }}
            label="Tags"
            value={"1"}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
            }}
            label="Crawl"
            value={"2"}
            {...a11yProps(1)}
          />
          <Tab
            style={{
              textTransform: "none",
            }}
            label="Subscribe"
            value={"3"}
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel value="1">
          <TagSettings />
        </TabPanel>
        <TabPanel value="2">
          <CrawlSettings />
        </TabPanel>
        <TabPanel value="3">
          <SubscribeSettings />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Setting;

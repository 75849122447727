import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { Login, LoginRequest } from "../interfaces/login-interface";
import authService from "../auth-service";

interface MutationData {
  data: LoginRequest;
}

export function useLogin(configOptions: UseMutationOptions<any, any, any, Login> = {}) {
  return useMutation(({ data }: MutationData) => authService.login(data), {
    onSuccess: (data: any) => {},
    onSettled: () => {},
    ...configOptions,
  });
}

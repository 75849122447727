import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import settingsService from "../settings-service";
import { CrawlLogGetREsponse } from "../interfaces/crawl-interface";

export function useGetCrawlLog(
  page: number | null = null,
  limit: number | null = null,
  configOptions: UseQueryOptions<CrawlLogGetREsponse> = {}
) {
  return useQuery({
    // enabled: Boolean(businessId),
    queryKey: [settingsService.crawlLogQueryKey, page, limit],
    queryFn: () => settingsService.getCrawlLog(page, limit),
    retryDelay: 1000 * 30,
    staleTime: 1000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess(data) {},
    onError: (err) => {},
    onSettled(data, error: any) {
      if (!data || error?.response?.status === 403) {
        // do something in response
      }
    },
    ...configOptions,
  });
}

import { formatInTimeZone } from "date-fns-tz";
import NoDataSpinner from "../../../components/no-data-spinner";
import { useGetSubscribes } from "../../../services/hooks/use-get-subscribes";
import { ReactComponent as EmptyState } from "../../../../assets/svg/giveaway-empty-state.svg";
import { useState } from "react";
import { TablePagination } from "@mui/material";

const SubscribeSettings = () => {
  const [page, setPage] = useState<number>(1);

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const { data, isLoading } = useGetSubscribes(page, rowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };
  return (
    <>
      {isLoading ? (
        <NoDataSpinner fullPage={false} />
      ) : (
        <div className="flex flex-col w-full max-w-7xl mx-auto">
          <div className=" overflow-x-auto mt-6">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {/* table header */}
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="grid items-center w-full h-16 grid-cols-6 text-gray-500 bg-gray-50 xl:text-sm text-xs ">
                    <div className="flex items-center justify-start pl-4 text-center col-span-2">
                      Email
                    </div>
                    <div className="flex items-center justify-start pl-4 text-center col-span-2">
                      Status
                    </div>
                    <div className="flex items-center justify-center text-center col-span-2">
                      Created At
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex flex-col bg-white divide-y divide-gray-200">
                    {data && data?.subscribers?.length > 0 ? (
                      data?.subscribers?.map((subscriber) => {
                        return (
                          <div
                            className={`bg-cover bg-center grid w-full h-20 grid-cols-6 font-normal  lg:text-sm text-xs ${"text-[#464747]"}`}
                            key={subscriber._id}
                          >
                            <div className="relative flex flex-col items-start justify-center text-center pl-4 col-span-2 ">
                              <div className="text-base text-gray-900">
                                {subscriber.email || ""}
                              </div>
                            </div>
                            <div className="relative flex flex-col items-start justify-center text-center  col-span-2 ">
                              <div className="text-base text-gray-900">
                                {subscriber.unsubscribe
                                  ? "Unsubscribed"
                                  : subscriber.recived
                                  ? "Subscribed"
                                  : "Unknown"}
                              </div>
                            </div>
                            <div className="flex flex-col items-center justify-center text-center px-1 col-span-2">
                              <div className="font-medium ">
                                {subscriber.createdAt ? (
                                  <div className="">
                                    {formatInTimeZone(
                                      new Date(subscriber?.createdAt),
                                      "Europe/London",
                                      "dd/MM/yyyy HH:mm"
                                    )}
                                  </div>
                                ) : (
                                  <span className="text-[#7F8080]">---</span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="w-full h-full m-auto flex flex-col gap-4 items-center justify-center text-gray-600 py-10">
                        <EmptyState />
                        <div className="">No data</div>
                      </div>
                    )}

                    {data && data?.subscribers?.length && (
                      <div>
                        <div className=" w-full h-20 text-sm font-medium text-gray-900 flex items-center justify-between bg-gray-100 px-8">
                          <div className="">
                            {(page - 1) * rowsPerPage + 1} - {page * rowsPerPage} of {data?.count}
                          </div>
                          <TablePagination
                            component="div"
                            nextIconButtonProps={{
                              disabled: data?.count / page <= rowsPerPage ? true : false,
                            }}
                            backIconButtonProps={{
                              disabled: page === 1 ? true : false,
                            }}
                            count={data?.count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[2, 5, 10, 25, 50]}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={function defaultLabelDisplayedRows({
                              from,
                              to,
                              count,
                            }) {
                              return `${from > count ? "" : `${from}-`}${to} of ${
                                count !== -1 ? count : `more than ${to}`
                              }`;
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscribeSettings;

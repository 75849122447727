import GiveawayForm from "../../components/giveaway-form/giveaway-form";

const AddGiveaway = () => {
  return (
    <div className="min-h-screen h-full p-4 bg-gray-100">
      <GiveawayForm />
    </div>
  );
};

export default AddGiveaway;

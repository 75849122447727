import { useState } from "react";
import Menu from "@mui/material/Menu";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

interface TagListActionsProps {
  setOpenDelete: (arg0: boolean) => void;
  setOpenForm: (arg0: boolean) => void;
}

const TagListActions = ({ setOpenForm, setOpenDelete }: TagListActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;

  return (
    <div>
      <button
        className="bg-[#EDF0F0] p-2  rounded-md"
        type="button"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
      </button>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenForm(true);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDelete(true);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
export default TagListActions;

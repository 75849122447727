import { formatInTimeZone } from "date-fns-tz";
import { useGetCrawlLog } from "../../../../services/hooks/use-get-crawl-log";
// import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { TablePagination } from "@mui/material";
import NoDataSpinner from "../../../../components/no-data-spinner";
import { ReactComponent as EmptyState } from "../../../../../assets/svg/giveaway-empty-state.svg";

const CrawlLog = () => {
  // const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<number>(1);

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { data, isLoading } = useGetCrawlLog(page, rowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
      // setSearchParams({ page: `${newPage}`, limit: `${rowsPerPage}` });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    // setSearchParams({ page: `1`, limit: `${rowsPerPage}` });
    setPage(1);
  };

  return (
    <>
      {isLoading ? (
        <NoDataSpinner fullPage={false} />
      ) : (
        <div className="overflow-x-auto mt-6">
          <div className="inline-block min-w-full py-2 align-middle px-1">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {/* table header */}
              <div className="min-w-full divide-y divide-gray-300">
                <div className="grid items-center w-full h-16 grid-cols-9 text-gray-500 bg-gray-50 xl:text-sm text-xs ">
                  <div className="flex items-center justify-start pl-4 text-center col-span-2">
                    Number
                  </div>
                  <div className="flex items-center justify-center text-center col-span-3">
                    Trigger At
                  </div>
                  <div className="flex items-center justify-center text-center col-span-3">
                    Trigger By
                  </div>
                  <div className="flex items-center justify-center text-center col-span-1">
                    Result
                  </div>
                </div>
              </div>

              <div>
                <div className="flex flex-col bg-white divide-y divide-gray-200">
                  {data && data?.data?.logs?.length > 0 ? (
                    data?.data?.logs?.map((log, index) => {
                      return (
                        <div
                          className={`bg-cover bg-center grid w-full h-20 grid-cols-9 font-normal  ${"text-[#464747]"}`}
                          key={log._id}
                        >
                          <div className="relative flex flex-col items-start justify-center text-center pl-4 col-span-2 ">
                            <div className="text-base text-gray-900">{index + 1}</div>
                          </div>
                          <div className="flex flex-col items-center justify-center text-center px-1 col-span-3">
                            <div className="font-medium ">
                              {log.time ? (
                                <div className="">
                                  {formatInTimeZone(
                                    new Date(log?.time),
                                    "Europe/London",
                                    "dd/MM/yyyy HH:mm"
                                  )}
                                </div>
                              ) : (
                                <span className="text-[#7F8080]">---</span>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col items-center justify-center text-center px-1 col-span-3">
                            <div className="font-medium ">{log?.type}</div>
                          </div>

                          <div className="flex items-center justify-center gap-1 text-center col-span-1 capitalize">
                            {log?.giveAways?.length || "---"}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-full h-full m-auto flex flex-col gap-4 items-center justify-center text-gray-600 py-10">
                      <EmptyState />
                      <div className="">No data!</div>
                    </div>
                  )}

                  {data && data?.data?.logs?.length && (
                    <div>
                      <div className=" w-full h-20 text-sm font-medium text-gray-900 flex items-center justify-between bg-gray-100 px-8">
                        <div className="">
                          {(page - 1) * rowsPerPage + 1} - {page * rowsPerPage} of{" "}
                          {data?.data?.count}
                        </div>
                        <TablePagination
                          component="div"
                          nextIconButtonProps={{
                            disabled: data?.data?.count / page <= rowsPerPage ? true : false,
                          }}
                          backIconButtonProps={{
                            disabled: page === 1 ? true : false,
                          }}
                          count={data?.data?.count}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelDisplayedRows={function defaultLabelDisplayedRows({
                            from,
                            to,
                            count,
                          }) {
                            return `${from > count ? "" : `${from}-`}${to} of ${
                              count !== -1 ? count : `more than ${to}`
                            }`;
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    // <div className=""></div>
  );
};
export default CrawlLog;

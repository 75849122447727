import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import giveawaysService from "../giveaway-service";
import settingsService from "../settings-service";

export function useManualCrawl(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();

  return useMutation(() => settingsService.manualCrawl(), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([giveawaysService.categoriesQueryKey]);
    },
    onSettled: () => {},
    ...configOptions,
  });
}

import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import settingsService from "../settings-service";

interface MutationData {
  hour: number;
}

export function useChangeJob(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();

  return useMutation(({ hour }: MutationData) => settingsService.changeJobHour(hour), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([settingsService.crawledSettingsQueryKey]);
      // toast.success(`tag created.`);
    },
    onSettled: () => {},
    ...configOptions,
  });
}

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { GiveawaysGetResponse } from "../../../services/interfaces/giveaway-interface";
import giveawaysService from "../../../services/giveaway-service";
import ActiveGiveawayModal from "./active-giveaway-modal";
import DeleteGiveaawayModal from "./delete-giveaway-modal";
import FeaturedGiveawayModal from "./featured-giveaway-modal";
import TableAction from "./table-action";
import { formatInTimeZone } from "date-fns-tz";
import HashuredImage from "../../../../assets/images/hashur.png";
import { useSearchParams } from "react-router-dom";
import { TablePagination } from "@mui/material";
import { ReactComponent as EmptyState } from "../../../../assets/svg/giveaway-empty-state.svg";

interface GiveawayTableBodyProps {
  giveawayList: GiveawaysGetResponse["data"]["giveAways"] | undefined;
  page: number;
  setPage(page: number): void;
  rowsPerPage: number;
  setRowsPerPage(rowsPerPage: number): void;
  count: number;
}

const GiveawayTableBody = ({
  giveawayList,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  count,
}: GiveawayTableBodyProps) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openActive, setOpenActive] = useState<boolean>(false);
  const [openFeatured, setOpenFeatured] = useState<boolean>(false);
  const [selectedGiveaway, setSelectedGiveaway] = useState<
    GiveawaysGetResponse["data"]["giveAways"][0] | null
  >(null);

  const { mutate: deleteGiveaway, isLoading: deleteIsLoading } = useMutation(
    giveawaysService.deleteGiveaway,
    {
      onSuccess: (data) => {
        toast.success(`Giveaway Deleted.`);
        queryClient.invalidateQueries([giveawaysService.giveawaysQueryKey]);
        setOpenDelete(false);
      },
      onError: (err: AxiosError<Error>) => {
        toast.error(`${err.response?.data.message}`);
        queryClient.invalidateQueries([giveawaysService.giveawaysQueryKey]);
        setOpenDelete(false);
      },
    }
  );
  const handleDeleteGiveaway = (id: string) => {
    deleteGiveaway({ giveawayId: id });
  };

  const { mutate: flagGiveaway, isLoading: flagIsLoading } = useMutation(
    giveawaysService.flagGiveaway,
    {
      onSuccess: (data) => {
        toast.success(
          `${
            selectedGiveaway?.flag
              ? "Giveaway removed from featured."
              : "Giveaway added to featured."
          }`
        );
        queryClient.invalidateQueries([giveawaysService.giveawaysQueryKey]);
        setOpenFeatured(false);
      },
      onError: (err: AxiosError<Error>) => {
        toast.error(`${err.response?.data.message}`);
        queryClient.invalidateQueries([giveawaysService.giveawaysQueryKey]);
        setOpenFeatured(false);
      },
    }
  );

  const handleFlaggedGiveaway = (id: string, flag: boolean) => {
    flagGiveaway({ giveawayId: id, flag });
  };

  const { mutate: activeGiveaway, isLoading: activeIsLoading } = useMutation(
    giveawaysService.activeGiveaway,
    {
      onSuccess: (data) => {
        toast.success(
          `Giveaway ${selectedGiveaway?.status === "Active" ? "Deactivate" : "Activate"}`
        );
        queryClient.invalidateQueries([giveawaysService.giveawaysQueryKey]);
        setOpenActive(false);
      },
      onError: (err: AxiosError<Error>) => {
        toast.error(`${err.response?.data.message}`);
        queryClient.invalidateQueries([giveawaysService.giveawaysQueryKey]);
        setOpenActive(false);
      },
    }
  );

  const handleActiveGiveaway = (id: string, status: string) => {
    activeGiveaway({ giveawayId: id, status });
  };

  const handleGiveawayDates = (date: Date) => {
    return {
      month: formatInTimeZone(date, "Europe/London", "MMMM"),
      day: formatInTimeZone(date, "Europe/London", "dd"),
      hour: formatInTimeZone(date, "Europe/London", "HH:mm"),
    };
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
      setSearchParams({ page: `${newPage}`, status: searchParams.get("status") || "All" });

      // setSearchParams({ page: `${newPage}`, status: selectedTab, query: keyword });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  return (
    <div>
      <DeleteGiveaawayModal
        open={openDelete}
        setOpen={setOpenDelete}
        deleteAction={handleDeleteGiveaway}
        giveawayId={selectedGiveaway?._id || ""}
        buttonLoading={deleteIsLoading}
      />
      <ActiveGiveawayModal
        open={openActive}
        setOpen={setOpenActive}
        status={selectedGiveaway?.status || "Active"}
        giveawayId={selectedGiveaway?._id || ""}
        buttonLoading={activeIsLoading}
        activeAction={handleActiveGiveaway}
      />
      <FeaturedGiveawayModal
        open={openFeatured}
        setOpen={setOpenFeatured}
        featuredAction={handleFlaggedGiveaway}
        giveawayId={selectedGiveaway?._id || ""}
        buttonLoading={flagIsLoading}
        flag={selectedGiveaway?.flag || false}
      />
      <div className="flex flex-col bg-white divide-y divide-gray-200">
        {giveawayList && giveawayList.length > 0 ? (
          giveawayList?.map((giveaway) => {
            return (
              <div
                className={`bg-cover bg-center grid w-full h-20 grid-cols-9 font-normal  lg:text-sm text-xs ${"text-[#464747]"}`}
                key={giveaway._id}
                style={{
                  backgroundImage: giveaway.flag ? `url(${HashuredImage})` : "none",
                }}
                // className={`bg-cover bg-center grid w-full h-20 grid-cols-11 font-normal  lg:text-sm text-xs  hover:bg-gray-100 cursor-pointer ${
                //   giveaway.status === "Finished" ? "text-gray-300" : "text-[#464747]"
                // }`}
                // key={giveaway._id}
                // onClick={() => {
                //   navigate(
                //     `/business/giveaway-runner/giveaways/single-giveaway?id=${giveaway._id}`
                //   );
                // }}
                // style={{
                //   backgroundImage:
                //     giveaway.status === "Finished" ? `url(${HashoredImage})` : "none",
                // }}
              >
                <div className="relative flex items-center justify-start text-center pl-4 col-span-2 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          (giveaway.files && giveaway?.files[0]?.url) ??
                          require("../../../../assets/svg/empty-state (1).svg").default
                        }
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="font-normal text-gray-900 text-xs line-clamp-1">
                        {giveaway.name || ""}
                      </div>
                      {/* <div className="text-gray-500 ">{giveaway.flag ? "Featured" : "Play down"}</div> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center text-center px-1 col-span-1">
                  <div className="font-medium ">
                    {giveaway.startDate ? (
                      <div className="text-xs">
                        {handleGiveawayDates(new Date(giveaway.startDate || "")).month}

                        <div className="text-xl font-semibold">
                          {handleGiveawayDates(new Date(giveaway.startDate || "")).day}
                        </div>
                        <div className="text-sm font-semibold">
                          {handleGiveawayDates(new Date(giveaway.startDate || "")).hour}
                        </div>
                      </div>
                    ) : (
                      <span className="text-[#7F8080]">---</span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center text-center px-1 col-span-1">
                  {giveaway.endDate ? (
                    <div className="text-xs">
                      {handleGiveawayDates(new Date(giveaway.endDate || "")).month}

                      <div className="text-xl font-semibold">
                        {handleGiveawayDates(new Date(giveaway.endDate || "")).day}
                      </div>
                      <div className="text-sm font-semibold">
                        {handleGiveawayDates(new Date(giveaway.endDate || "")).hour}
                      </div>
                    </div>
                  ) : (
                    <span className="text-[#7F8080]">---</span>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center text-center px-1 col-span-1">
                  {giveaway.winnerAnnouncement ? (
                    <div className="text-xs">
                      {handleGiveawayDates(new Date(giveaway.winnerAnnouncement || "")).month}

                      <div className="text-xl font-semibold">
                        {handleGiveawayDates(new Date(giveaway.winnerAnnouncement || "")).day}
                      </div>
                      <div className="text-sm font-semibold">
                        {handleGiveawayDates(new Date(giveaway.winnerAnnouncement || "")).hour}
                      </div>
                    </div>
                  ) : (
                    <span className="text-[#7F8080]">---</span>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center text-center  px-1 col-span-1">
                  Caption
                </div>

                <div className="flex relative items-center justify-center gap-2 text-center px-1 col-span-1 capitalize">
                  {giveaway.submitted}
                </div>

                <div
                  className="flex items-center justify-end gap-1 text-center col-span-1 capitalize"
                  onClick={() => setSelectedGiveaway(giveaway)}
                >
                  <TableAction
                    instagramUrl={giveaway?.urls?.instagram}
                    giveawayId={giveaway._id}
                    openDelete={setOpenDelete}
                    openActive={setOpenActive}
                    openFeatured={setOpenFeatured}
                    status={giveaway.status}
                    flag={giveaway.flag}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-full h-full m-auto flex flex-col gap-4 items-center justify-center text-gray-600 py-10">
            <EmptyState />
            <div className="">No giveaway!</div>
          </div>
        )}

        {giveawayList && giveawayList?.length > 0 && (
          <div>
            <div className=" w-full h-20 text-sm font-medium text-gray-900 flex items-center justify-between bg-gray-100 px-8">
              <div className="">
                {(page - 1) * rowsPerPage + 1} - {page * rowsPerPage} of {count}
              </div>
              <TablePagination
                component="div"
                nextIconButtonProps={{
                  disabled: count / page <= rowsPerPage ? true : false,
                }}
                backIconButtonProps={{
                  disabled: page === 1 ? true : false,
                }}
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                  return `${from > count ? "" : `${from}-`}${to} of ${
                    count !== -1 ? count : `more than ${to}`
                  }`;
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default GiveawayTableBody;

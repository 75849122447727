import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { CrawledSettingsGetResponse } from "../interfaces/crawl-interface";
import settingsService from "../settings-service";

export function useGetCrawledSettings(
  configOptions: UseQueryOptions<CrawledSettingsGetResponse> = {}
) {
  return useQuery({
    // enabled: Boolean(businessId),
    queryKey: [settingsService.crawledSettingsQueryKey],
    queryFn: () => settingsService.getCrawlSettings(),
    retryDelay: 1000 * 30,
    staleTime: 1000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess(data) {},
    onError: (err) => {},
    onSettled(data, error: any) {
      if (!data || error?.response?.status === 403) {
        // do something in response
      }
    },
    ...configOptions,
  });
}

import { GiveawaysGetResponse, SubmitGiveawayType } from "./interfaces/giveaway-interface";
import http from "./axios-instance";
import { CategoriesGetResponse } from "./interfaces/category-interface";
import { DashboardGetResponse } from "./interfaces/dashboard-interface";
import { SingleGiveawayResponse } from "./interfaces/single-giveaway-interface";

const giveawaysQueryKey = "giveaways";
const singleGiveawaysQueryKey = "single-giveaway";
const categoriesQueryKey = "categories-query";
const dashboardQueryKey = "dashboard-query";

const getGiveaways = async (
  page: number | null = null,
  limit: number | null = null,
  expired: boolean | undefined = undefined,
  submitted: string | undefined = undefined
): Promise<GiveawaysGetResponse> => {
  const params = {
    expired: expired ? true : undefined,
    page,
    limit,
    submitted: submitted === "All" ? undefined : submitted,
  };
  const url = `${process.env.REACT_APP_BASE_URL}/admin/getAll`;
  const { data } = await http.get(url, { params });
  return data;
};

const getGiveawaysDashboard = async (): Promise<DashboardGetResponse> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/dashboard`;
  const { data } = await http.get(url);
  return data;
};

const getCategories = async (
  page: number | null = null,
  limit: number | null = null,
  keyword: string | null = null
): Promise<CategoriesGetResponse> => {
  const params = { page, limit, keyword };
  const url = `${process.env.REACT_APP_BASE_URL}/category`;
  const { data } = await http.get(url, { params });
  return data;
};

const getSingleGiveaway = async (giveawayId: string): Promise<SingleGiveawayResponse> => {
  // const [_, giveawayId] = queryKey;
  const params = { id: giveawayId };
  const url = `${process.env.REACT_APP_BASE_URL}/admin/getGiveAway`;
  const res = await http.get(url, { params });
  return res.data;
};

const submitGiveawayForm = async ({
  id,
  name,
  url,
  details,
  award,
  files,
  isEdit = false,
  startDate,
  endDate,
  country,
  city,
  category,
  winnerAnnouncement,
}: SubmitGiveawayType): Promise<any> => {
  if (!isEdit) {
    const postUrl = `${process.env.REACT_APP_BASE_URL}/admin/giveAway/create`;
    const res = await http.post(postUrl, {
      name,
      url,
      caption: details,
      award,
      country,
      city,
      files,
      startDate,
      category,
      endDate,
      winnerAnnouncement,
    });

    return res.data.data;
  } else {
    const patchUrl = `${process.env.REACT_APP_BASE_URL}/admin/giveAway/edit`;
    const data = {
      id,
      name,
      url,
      caption: details,
      award,
      files,
      country,
      city,
      startDate,
      category,
      endDate,
      winnerAnnouncement,
    };
    const res = await http.patch(patchUrl, data);
    return res.data.data;
  }
};

const deleteGiveaway = async ({ giveawayId }: { giveawayId: string }): Promise<any> => {
  const data = { id: giveawayId };
  const url = `${process.env.REACT_APP_BASE_URL}/admin/delete`;
  const res = await http.delete(url, { data });
  return res.data.data;
};

const flagGiveaway = async ({
  giveawayId,
  flag,
}: {
  giveawayId: string;
  flag: boolean;
}): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/flag`;
  const res = await http.post(url, { id: [giveawayId], flag });
  return res.data.data;
};

const activeGiveaway = async ({
  giveawayId,
  status,
}: {
  giveawayId: string;
  status: string;
}): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_URL}/admin/active`;
  const res = await http.post(url, { id: giveawayId, status });
  return res.data.data;
};

const giveawaysService = {
  giveawaysQueryKey,
  getGiveaways,
  singleGiveawaysQueryKey,
  getSingleGiveaway,
  submitGiveawayForm,
  deleteGiveaway,
  flagGiveaway,
  activeGiveaway,
  categoriesQueryKey,
  getCategories,
  dashboardQueryKey,
  getGiveawaysDashboard,
};

export default giveawaysService;

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface NoDataSpinnerProps {
  fullPage?: boolean;
}

const NoDataSpinner = ({ fullPage = true }: NoDataSpinnerProps) => {
  return (
    <div
      className={`flex items-center justify-center w-full h-full flex-1 bg-white rounded-md ${
        fullPage ? "absolute right-0 bottom-0" : ""
      }`}
    >
      <div className="flex flex-col items-center justify-center space-y-9">
        <span className="text-gray-700 font-bold text-2xl">Please Wait.</span>
        <Box sx={{ display: "flex" }}>
          <CircularProgress color="secondary" />
        </Box>
      </div>
    </div>
  );
};

export default NoDataSpinner;

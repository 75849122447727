import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon, CheckIcon } from "@heroicons/react/24/outline";
import Modal from "../../../components/modal";
import Spinner from "../../../components/spinner";

type ActiveGiveawayModalProps = {
  open: boolean;
  setOpen: (arg0: boolean) => void;
  status: string;
  giveawayId: string;
  activeAction: (id: string, status: string) => void;
  buttonLoading: boolean;
};

const ActiveGiveawayModal = ({
  setOpen,
  open,
  status,
  giveawayId,
  activeAction,
  buttonLoading,
}: ActiveGiveawayModalProps) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="sm:flex sm:items-start">
          <div
            className={`${
              status === "Active" ? "bg-red-100" : "bg-green-100"
            } mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10`}
          >
            {status === "Active" ? (
              <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            ) : (
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            )}
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {status === "Active" ? "Deactivate" : "Activate"} giveaway
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to {status === "Active" ? "Deactivate" : "Activate"} this
                giveaway?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={buttonLoading}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => activeAction(giveawayId, status === "Active" ? "InActive" : "Active")}
          >
            {!buttonLoading ? (
              status === "Active" ? (
                "Deactivate"
              ) : (
                "Activate"
              )
            ) : (
              <Spinner size="small" />
            )}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ActiveGiveawayModal;

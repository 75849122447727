import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        sx: {
          color: '#ef4444',
          fontSize: '14px',
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'capitalize',
          height: '48px',
          width: '144px',
          letterSpacing: '0.5px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#4f46e5',
    },
    secondary: {
      main: '#22c55e',
      contrastText: '#ffffff',
    },
    error: {
      main: '#ef4444',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { LoginRequest } from "../../../services/interfaces/login-interface";
import { useAuthSlice } from "../../../store/auth-slice";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import FormError from "../../../components/form-error";
import Spinner from "../../../components/spinner";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import React from "react";
import { useLogin } from "../../../services/hooks/use-login";

const schema = yup
  .object({
    userName: yup.string().required("This field is required"),
    password: yup
      .string()
      .min(6, ({ min }) => `must be at least ${min} characters`)
      .required("This field is required"),
  })
  .required();

const LoginForm = () => {
  const recaptchaRef = React.createRef<any>();

  const navigate = useNavigate();
  const updateUser = useAuthSlice((state) => state.updateUser);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    resolver: yupResolver(schema),
  });

  const loginUser = useLogin();

  const onSubmit: SubmitHandler<LoginRequest> = (data) => {
    loginUser.mutate(
      { data: { ...data, captcha: recaptchaToken } },
      {
        onSuccess(data) {
          updateUser(data);
          toast.success(`Welcome ${data.data.userName}, Let's go`);
          navigate("/");
        },
      }
    );
  };

  const onReCAPTCHAChange = (captchaCode: any) => {
    if (!captchaCode) {
      return;
    }
    setRecaptchaToken(captchaCode);
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label htmlFor="user-name" className="block text-sm font-medium text-gray-700">
          User name
        </label>
        <div className="mt-1">
          <input
            id="user-name"
            {...register("userName")}
            type="text"
            autoComplete="user-name"
            required
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <p className="pt-2 text-red-500 capitalize first-letter:capitalize">
          {errors.userName?.message}
        </p>
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          Password
        </label>
        <div className="mt-1">
          <input
            {...register("password")}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <p className="pt-2 text-red-500 capitalize first-letter:capitalize">
          {errors.password?.message}
        </p>
      </div>
      <div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="normal"
          onChange={onReCAPTCHAChange}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
          <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
            Remember me
          </label>
        </div>

        <div className="text-sm">
          <a href="/" className="font-medium text-prm">
            Forgot your password?
          </a>
        </div>
      </div>

      <div className="text-white hover:text-prm">
        {/* <LoadingButton
          variant="text"
          type="submit"
          color="inherit"
          loadingIndicator={<CircularProgress style={{ color: "#ffffff" }} size={16} />}
          disabled={loginUser.isLoading}
          loading={loginUser.isLoading}
          sx={{
            minWidth: "250px",
            border: "1px solid",
            backgroundColor: "#2D0067",
            color: "fff",
            width: "100%",
          }}
        >
          Sign In
        </LoadingButton> */}
        <button
          type="submit"
          className="flex w-full justify-center rounded-md border border-prm hover:text-prm bg-prm py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-prm focus:ring-offset-2"
          disabled={loginUser.isLoading}
        >
          {!loginUser.isLoading ? "Sign in" : <Spinner size="small" />}
        </button>
      </div>
      <FormError error={loginUser.error} />
    </form>
  );
};
export default LoginForm;

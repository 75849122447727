import LoginForm from "./components/login-form";
import { ReactComponent as LoginLogo } from "../../../assets/svg/Frame 19.svg";

const Login = () => {
  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 relative">
        <img
          className="absolute top-0 left-0 bottom-0 h-screen w-full blur-sm z-[-1]"
          src={require("../../../assets/images/login-image.png")}
          // src={require("../../../assets/images/login-image.jpg")}
          alt=""
        />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="w-26">
            <LoginLogo className="h-full w-full" />
          </div>
          {/* <img
            className="mx-auto h-16 w-auto"
            src={require("../../../assets/svg/login-logo.svg").default}
            alt="selected-giveaway"
          /> */}
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-prm">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 overflow-hidden">
            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;

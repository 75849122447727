import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { CheckIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isAfter, isBefore, startOfDay, startOfHour } from "date-fns";
import { zonedTimeToUtcFn } from "../../utils/format-date";
import { Autocomplete, Button, CircularProgress, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useSubmitGiveaway } from "../../services/hooks/use-giveaway-form";
import { LoadingButton } from "@mui/lab";
import FormError from "../form-error";
import { useGetCategories } from "../../services/hooks/use-get-categories";
import React from "react";
import { CountryListType, countriesList } from "../../services/data/countries";
// import { useDebounce } from "react-use";
import { useSingleGiveaway } from "../../services/hooks/use-get-giveaways";
import { useDebounce } from "use-debounce";

const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
export const FILE_SIZE = 1 * 1024 * 1024;

const b64Array = async (filesArray: FileList) => {
  let images: any = [];
  for await (const el of filesArray) {
    const b64 = (await toBase64(el as File)) as string;
    var base64result = b64.split(",")[1];
    images = [...images, base64result];
    // images = [...images, b64];
  }
  // return base64result
  return images;
};

// interface GiveawayFormProps {
//   // singleGiveaway: SingleGiveawayResponse["data"] | undefined;
// }

type FormData = {
  name: string;
  url: string;
  details: string;
  award: string;
  country: CountryListType | null;
  city: string;
  image: FileList | undefined;
  previewImage: string | undefined;
  endDate: Date | null;
  winnerAnnouncement: Date | null;
  startDate: Date | null;
  categories: {
    _id: string;
    name: string;
  }[];
  // categories: string[] | undefined;
};

const schema = yup.object({
  name: yup.string(),
  city: yup.string(),
  country: yup.mixed().required("Please select a country"),
  startDate: yup
    .mixed()
    // .date()
    // .nullable()
    // .typeError("Invalid Time")
    .test("beforeDate", "Start must be before end", (val, ctx) => {
      const end = ctx.parent.endDate as Date;
      if (val) {
        if (val instanceof Date) {
          if (isAfter(val!, end)) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
  winnerAnnouncement: yup
    .mixed() // .date()
    // .nullable()
    // .typeError("Invalid Time")
    .test("AfterDate", "Winner Announcement must be after end", (val, ctx) => {
      const end = ctx.parent.endDate as Date;
      if (val) {
        if (val instanceof Date) {
          if (isBefore(val!, end)) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
  endDate: yup
    .mixed() // .date()
    // .nullable()
    // .typeError("Invalid Time")
    .test("AfterDate", "End must be after start", (val, ctx) => {
      const start = ctx.parent.startDate as Date;
      if (val) {
        if (val instanceof Date) {
          if (isBefore(val!, start)) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
  url: yup.string().required(),
  award: yup.string(),
  image: yup.mixed().required("Please Upload Image"),
});

const GiveawayForm = () => {
  const [query, setQuery] = useState("");

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  // const [inputValue, setInputValue] = useState("");
  const [openSelect, setOpenSelect] = useState(false);
  const debouncedValue = useDebounce(query, 400);
  const { data: categoriesData, isLoading, refetch } = useGetCategories(null, null, null);
  const categoriesOptions = (categoriesData?.data ?? []).map((item) => {
    return { _id: item._id, name: item.name };
  });
  const [tagState, setTagState] = useState<
    {
      _id: string;
      name: string;
    }[]
  >([]);
  const [searchParams] = useSearchParams();
  const giveawayId = searchParams.get("giveawayId") || "";
  const { data: singleGiveaway } = useSingleGiveaway(giveawayId);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      image: undefined,
      award: singleGiveaway?.data?.award ?? "",
      country:
        countriesList.find((item) => item["alpha-3"] === singleGiveaway?.data?.country) ?? null,
      city: singleGiveaway?.data?.city ?? "",
      details: singleGiveaway?.data?.caption ?? "",
      name: singleGiveaway?.data?.name ?? "",
      url: singleGiveaway?.data?.urls?.instagram ?? "",
      endDate: singleGiveaway?.data?.endDate ? new Date(singleGiveaway?.data?.endDate || "") : null,
      startDate: singleGiveaway?.data?.startDate
        ? new Date(singleGiveaway?.data?.startDate || "")
        : null,
      winnerAnnouncement: singleGiveaway?.data?.winnerAnnouncement
        ? new Date(singleGiveaway?.data?.winnerAnnouncement || "")
        : null,
      categories:
        singleGiveaway?.data?.category.map((item) => {
          return {
            _id: item._id,
            name: item?.name,
          };
        }) || [],
    },
  });

  const submitGiveaway = useSubmitGiveaway();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    let image: any = null;
    if (data?.image) {
      image = await b64Array(data.image);
    }

    submitGiveaway.mutate(
      {
        data: {
          id: singleGiveaway?.data?._id || undefined,
          name: data.name,
          url: data.url.includes("https://") ? data.url : `https://${data.url}`,
          award: data.award || undefined,
          details: data.details,
          country: data?.country?.["alpha-3"] || undefined,
          city: data.city || undefined,
          files: image && image.length ? image : undefined,
          endDate: data.endDate ? zonedTimeToUtcFn(data.endDate || "").toISOString() : null,
          startDate: data.startDate ? zonedTimeToUtcFn(data.startDate || "").toISOString() : null,
          winnerAnnouncement: data.winnerAnnouncement
            ? zonedTimeToUtcFn(data.winnerAnnouncement || "").toISOString()
            : null,
          isEdit: singleGiveaway?.data?._id ? true : false,
          category: data?.categories ? data?.categories.map((item) => item?._id) : undefined,
        },
      },
      {
        onSuccess: (data) => {
          navigate("/");
        },
      }
    );
  };

  const image = watch("image");

  useEffect(() => {
    if (singleGiveaway && categoriesData) {
      reset({
        image: undefined,
        award: singleGiveaway?.data?.award ?? "",
        details: singleGiveaway?.data?.caption ?? "",
        name: singleGiveaway?.data?.name ?? "",
        url: singleGiveaway?.data?.urls?.instagram ?? "",
        country:
          countriesList.find((item) => item["alpha-3"] === singleGiveaway?.data?.country) ?? null,
        city: singleGiveaway?.data?.city ?? "",
        endDate: singleGiveaway?.data?.endDate
          ? new Date(singleGiveaway?.data?.endDate || "")
          : null,
        startDate: singleGiveaway?.data?.startDate
          ? new Date(singleGiveaway?.data?.startDate || "")
          : null,
        winnerAnnouncement: singleGiveaway?.data?.winnerAnnouncement
          ? new Date(singleGiveaway?.data?.winnerAnnouncement || "")
          : null,
        categories:
          singleGiveaway?.data?.category?.map((item) => {
            return {
              _id: item._id,
              name: item.name,
            };
          }) || undefined,
      });
    }
  }, [reset, singleGiveaway, categoriesData]);

  // console.log(watch());

  return (
    <form
      className=" relative  flex flex-col  w-full  max-w-7xl mx-auto  sm:py-6  "
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="bg-white px-4 py-5 shadow rounded-lg sm:p-6">
        <div className="flex flex-col sm:gap-8 ga-4">
          <div className=" border-b pb-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Submit Giveaway</h3>
            <p className="mt-1 text-sm text-gray-500">
              Be careful what you share because this information will be displayed publicly.
            </p>
          </div>

          <div className="col-span-2">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-6 gap-x-6 gap-y-2">
                {/* website */}
                <div className="sm:col-span-3 col-span-6 ">
                  <Controller
                    name="url"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          type="text"
                          id="title"
                          label="Url"
                          variant="outlined"
                          inputProps={{
                            style: { fontWeight: "500" },
                          }}
                          fullWidth={true}
                          error={!!errors?.url}
                          helperText={errors?.url?.message ?? " "}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                {/* name */}
                <div className="sm:col-span-3 col-span-6">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          type="text"
                          id="title"
                          label="Name"
                          variant="outlined"
                          inputProps={{
                            style: { fontWeight: "500" },
                          }}
                          fullWidth={true}
                          error={!!errors?.name}
                          helperText={errors?.name?.message ?? " "}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                  <Autocomplete
                    id="country-select-demo"
                    options={countriesList}
                    autoHighlight
                    fullWidth
                    value={watch("country")}
                    onChange={(event: any, newValue) => {
                      setValue("country", newValue || null);
                    }}
                    isOptionEqualToValue={(option, value) => option["alpha-3"] === value["alpha-3"]}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        // {...field}
                        helperText={errors?.country?.message ?? " "}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>

                {/* city */}
                <div className="sm:col-span-3 col-span-6">
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          type="text"
                          id="city"
                          label="City"
                          variant="outlined"
                          inputProps={{
                            style: { fontWeight: "500" },
                          }}
                          fullWidth={true}
                          error={!!errors?.city}
                          helperText={errors?.city?.message ?? " "}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                {/* about */}
                <div className=" col-span-6">
                  <Controller
                    name="details"
                    control={control}
                    render={({ field }) => {
                      return (
                        <div className="col-span-6 mb-4 ">
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Caption
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="detail"
                              rows={5}
                              maxLength={512}
                              {...field}
                              className="block w-full text-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="This is place for your giveaway caption!"
                            />
                            <p className="pt-2 text-red-500 capitalize first-letter:capitalize">
                              {errors.details?.message}
                            </p>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>

                {/* start and end date*/}

                <div className="sm:col-span-3">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => {
                      return (
                        <DateTimePicker
                          label="Giveaway Start"
                          shouldDisableDate={(day: Date) => {
                            return isBefore(day, startOfDay(new Date()));
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              id="start"
                              fullWidth={true}
                              {...params}
                              error={!!errors.startDate}
                              helperText={errors.startDate?.message ?? " "}
                            />
                          )}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => {
                      return (
                        <DateTimePicker
                          label="Giveaway End"
                          shouldDisableDate={(day: Date) => {
                            return isBefore(day, startOfDay(watch("startDate")!));
                          }}
                          shouldDisableTime={(timeValue: number, type) => {
                            if (type === "hours") {
                              if (
                                isBefore(
                                  startOfDay(watch("startDate")!),
                                  startOfDay(watch("endDate")!)
                                )
                              ) {
                                return false;
                              } else {
                                return isBefore(
                                  timeValue,
                                  startOfHour(watch("startDate")!).getHours()
                                );
                              }
                            } else {
                              return false;
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              id="end"
                              fullWidth={true}
                              {...params}
                              error={!!errors.endDate}
                              helperText={errors.endDate?.message ?? " "}
                            />
                          )}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                {/* winner date*/}
                <div className="sm:col-span-3">
                  <Controller
                    name="winnerAnnouncement"
                    control={control}
                    render={({ field }) => {
                      return (
                        <DateTimePicker
                          label="Time to announce the winner"
                          shouldDisableDate={(day: Date) => {
                            return isBefore(day, startOfDay(watch("endDate")!));
                          }}
                          shouldDisableTime={(timeValue: number, type) => {
                            if (type === "hours") {
                              if (
                                isBefore(
                                  startOfDay(watch("endDate")!),
                                  startOfDay(watch("winnerAnnouncement")!)
                                )
                              ) {
                                return false;
                              } else {
                                return isBefore(
                                  timeValue,
                                  startOfHour(watch("endDate")!).getHours()
                                );
                              }
                            } else {
                              return false;
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              id="winner-announcement"
                              fullWidth={true}
                              {...params}
                              error={!!errors.winnerAnnouncement}
                              helperText={errors.winnerAnnouncement?.message ?? " "}
                            />
                          )}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>

                {/* award*/}
                <div className="sm:col-span-3 col-span-6 ">
                  <div className="sm:col-span-6 mb-2">
                    <Controller
                      name="award"
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            type="text"
                            id="title"
                            label="Award"
                            variant="outlined"
                            inputProps={{
                              style: { fontWeight: "500" },
                            }}
                            fullWidth={true}
                            error={!!errors?.award}
                            helperText={errors?.award?.message ?? " "}
                            {...field}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                {/* <div className="">{watch("categories")?.length}</div> */}

                {/* categories */}

                <div className="sm:col-span-3">
                  <Controller
                    name="categories"
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => {
                      return (
                        <Autocomplete
                          {...rest}
                          value={value}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          multiple
                          id="select-tag"
                          sx={{ width: "100%" }}
                          open={openSelect}
                          onOpen={() => {
                            setOpenSelect(true);
                          }}
                          onClose={() => {
                            setOpenSelect(false);
                          }}
                          disableCloseOnSelect
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          getOptionLabel={(option) => option.name}
                          options={categoriesOptions}
                          loading={isLoading}
                          renderOption={(props, option, { selected }) => (
                            <li
                              {...props}
                              className={`flex items-center justify-between gap-2  px-4 py-1 cursor-pointer ${
                                selected ? "bg-indigo-300" : ""
                              }`}
                            >
                              <div
                                className={`flex items-center justify-between font-normal  ${
                                  selected ? "text-gray-900" : "text-gray-500"
                                }`}
                              >
                                {option.name}
                              </div>
                              {selected && (
                                <div className="">
                                  <CheckIcon className="text-prm w-5 h-5" />
                                </div>
                              )}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="medium"
                              label="Tag"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </div>

                {/* cover photo */}
                <div className=" flex gap-6 col-span-6">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">Cover photo</label>
                    <label
                      htmlFor="file-upload"
                      className="mt-1 cursor-pointer flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600 justify-center">
                          <div className="relative rounded-md bg-white font-medium text-prm  hover:text-prm2 text-center">
                            <div>Upload a file</div>
                            <input
                              id="file-upload"
                              type="file"
                              className="sr-only"
                              accept="image/jpeg"
                              {...register("image")}
                              onClick={() => {
                                inputRef.current!.value = "";
                              }}
                            />
                          </div>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </label>
                    <p className="pt-2 text-red-500 capitalize first-letter:capitalize">
                      {errors.image?.message}
                    </p>
                  </div>
                  {watch("image")?.length ||
                  (singleGiveaway?.data?.files && singleGiveaway?.data?.files[0]?.url) ? (
                    <div className="w-1/4  mt-2  ">
                      <img
                        src={
                          image?.length
                            ? URL.createObjectURL(watch("image")![0])
                            : singleGiveaway?.data?.files[0]?.url
                        }
                        alt=""
                        className="!h-auto !w-auto rounded-md group-hover:scale-125	ease-out duration-300 object-contain"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <FormError error={submitGiveaway.error} />
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <Button
                type="button"
                variant="outlined"
                color="inherit"
                sx={{
                  minWidth: "250px",
                  border: "1px solid",
                  width: "100%",
                  borderRadius: "6px",
                  color: "#14CBC7",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="text"
                type="submit"
                color="inherit"
                loadingIndicator={<CircularProgress style={{ color: "#ffffff" }} size={16} />}
                disabled={submitGiveaway.isLoading}
                loading={submitGiveaway.isLoading}
                sx={{
                  minWidth: "250px",
                  border: "1px solid",
                  backgroundColor: "#14CBC7",
                  color: "#fff",
                  width: "100%",
                  borderRadius: "6px",
                  ":hover": {
                    color: "#14CBC7",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default GiveawayForm;

import { Button, TablePagination } from "@mui/material";
import { useGetCategories } from "../../../services/hooks/use-get-categories";
import { formatInTimeZone } from "date-fns-tz";
import TagFormModal from "./components/tag-form-modal";
import { useState } from "react";
import TagListActions from "./components/tag-list-actions";
import DeleteTagModal from "./components/delete-giveaway-modal";
import NoDataSpinner from "../../../components/no-data-spinner";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as EmptyState } from "../../../../assets/svg/giveaway-empty-state.svg";

const TagSettings = () => {
  const [openTagForm, setOpenTagForm] = useState<boolean>(false);
  const [openTagDelete, setOpenTagDelete] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<number>(parseInt(searchParams.get("page") || "1"));

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { data, isLoading } = useGetCategories(page, rowsPerPage);
  const [tagInfo, setTagInfo] = useState<
    { name: string; description: string; id: string } | undefined
  >(undefined);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
      setSearchParams({ page: `${newPage}` });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };
  return (
    <>
      {isLoading ? (
        <NoDataSpinner fullPage={false} />
      ) : (
        <div className="flex flex-col w-full max-w-7xl mx-auto">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Tag Settings</h1>
              {/* <p className="mt-2 text-sm text-gray-700">Tag Settings</p> */}
            </div>
            <Button
              variant="text"
              type="button"
              color="inherit"
              style={{
                border: "1px solid",
                backgroundColor: "#14CBC7",
                padding: " 8px 16px",
                color: "#fff",
                // width: "100%",
                borderRadius: "6px",
              }}
              onClick={() => {
                setTagInfo(undefined);
                setOpenTagForm(true);
              }}
            >
              Add Tag
            </Button>
          </div>
          <div className=" overflow-x-auto mt-6">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {/* table header */}
                <div className="min-w-full divide-y divide-gray-300">
                  <div className="grid items-center w-full h-16 grid-cols-9 text-gray-500 bg-gray-50 xl:text-sm text-xs ">
                    <div className="flex items-center justify-start pl-4 text-center col-span-4">
                      Tag
                    </div>
                    <div className="flex items-center justify-center text-center col-span-2">
                      Number Of Giveaways
                    </div>
                    <div className="flex items-center justify-center text-center col-span-2">
                      Created At
                    </div>
                    <div className="flex items-center justify-center text-center col-span-1">
                      ACTION
                    </div>
                  </div>
                </div>

                <div>
                  <TagFormModal
                    open={openTagForm}
                    setOpen={setOpenTagForm}
                    name={tagInfo?.name}
                    description={tagInfo?.description}
                    id={tagInfo?.id}
                  />
                  <DeleteTagModal
                    open={openTagDelete}
                    setOpen={setOpenTagDelete}
                    id={tagInfo?.id || undefined}
                  />
                  <div className="flex flex-col bg-white divide-y divide-gray-200">
                    {data && data?.data?.length > 0 ? (
                      data?.data?.map((tag) => {
                        return (
                          <div
                            className={`bg-cover bg-center grid w-full h-20 grid-cols-9 font-normal   ${"text-[#464747]"}`}
                            key={tag._id}
                          >
                            <div className="relative flex flex-col items-start justify-center text-center pl-4 col-span-4 ">
                              <div className="text-base text-gray-900">{tag.name || ""}</div>
                              <div className="font-normal text-gray-500 text-xs line-clamp-2">
                                {tag.description}
                              </div>
                            </div>
                            <div className="flex flex-col items-center justify-center text-center px-1 col-span-2">
                              <div className="font-medium ">{tag.numberOfUse}</div>
                            </div>
                            <div className="flex flex-col items-center justify-center text-center px-1 col-span-2">
                              <div className="font-medium ">
                                {tag.createdAt ? (
                                  <div className="">
                                    {formatInTimeZone(
                                      new Date(tag?.createdAt),
                                      "Europe/London",
                                      "dd/MM/yyyy HH:mm"
                                    )}
                                  </div>
                                ) : (
                                  <span className="text-[#7F8080]">---</span>
                                )}
                              </div>
                            </div>

                            <div
                              className="flex items-center justify-center gap-1 text-center col-span-1 capitalize"
                              onClick={() =>
                                setTagInfo({
                                  id: tag._id,
                                  description: tag.description,
                                  name: tag.name,
                                })
                              }
                            >
                              <TagListActions
                                setOpenDelete={setOpenTagDelete}
                                setOpenForm={setOpenTagForm}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="w-full h-full m-auto flex flex-col gap-4 items-center justify-center text-gray-600 py-10">
                        <EmptyState />
                        <div className="">No data!</div>
                      </div>
                    )}

                    {data && data?.data?.length && (
                      <div>
                        <div className=" w-full h-20 text-sm font-medium text-gray-900 flex items-center justify-between bg-gray-100 px-8">
                          <div className="">
                            {(page - 1) * rowsPerPage + 1} - {page * rowsPerPage} of {data?.count}
                          </div>
                          <TablePagination
                            component="div"
                            nextIconButtonProps={{
                              disabled: data?.count / page <= rowsPerPage ? true : false,
                            }}
                            backIconButtonProps={{
                              disabled: page === 1 ? true : false,
                            }}
                            count={data?.count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={function defaultLabelDisplayedRows({
                              from,
                              to,
                              count,
                            }) {
                              return `${from > count ? "" : `${from}-`}${to} of ${
                                count !== -1 ? count : `more than ${to}`
                              }`;
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TagSettings;

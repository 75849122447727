import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { SubmitGiveawayType } from "../interfaces/giveaway-interface";
import giveawaysService from "../giveaway-service";
import toast from "react-hot-toast";

interface MutationData {
  data: SubmitGiveawayType;
}

export function useSubmitGiveaway(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();

  return useMutation(({ data }: MutationData) => giveawaysService.submitGiveawayForm(data), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([giveawaysService.singleGiveawaysQueryKey]);
      queryClient.invalidateQueries([giveawaysService.giveawaysQueryKey]);
      toast.success(`Giveaway submitted.`);
    },
    onSettled: () => {},
    ...configOptions,
  });
}
